
import { defineComponent, ref, nextTick, toRefs } from 'vue';
import useRVSubmission from '@/hooks/SalesReporting/RVSubmission/Dealer/useRVSubmission';
import ChartModal from './ChartModal.vue';
import { message, TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
export default defineComponent({
    components: {
        ChartModal,
    },
    setup() {
        const searchCon = ref();
        const tableHeight = ref();
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight -
                220 -
                searchCon.value.$el.scrollHeight +
                'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 110;
        });
        const isEmpty = ref<any>(true);

        const {
            isDealer,
            qmDis,
            regionOnly,
            getSelectBuId,
            searchParams,
            qmOptions,
            activeTab,
            tableData,
            columns,
            searchClick,
            resetClick,
            downloadClick,
            tabChange,
            openYear,
            handlerOpenChange,
            handlerPanelChange,
            chartVisible,
            openChart,
            closeChart,
            downloadVisible,
            openDownload,
            closeDownload,
            downloadColumnsData,
            downloadTableData,
            downloadRowSelection,
            select,
            initValue,
            brandList,
            regionList,
            handleBuChange,
            buList,
            routerJump,
        } = useRVSubmission();
        if (buList.value.length > 0) {
            isEmpty.value = false;
        } else {
            message.error('您没有权限查看此页面！');
        }

        return {
            isDealer,
            qmDis,
            isEmpty,
            handleBuChange,
            regionOnly,
            getSelectBuId,
            searchCon,
            tableWidth,
            tableHeight,
            columns,
            ...toRefs(searchParams),
            searchParams,
            brandList,
            regionList,
            qmOptions,
            SHOW_PARENT,
            activeTab,
            tableData,
            searchClick,
            resetClick,
            downloadClick,
            tabChange,
            openYear,
            handlerOpenChange,
            handlerPanelChange,
            chartVisible,
            openChart,
            closeChart,
            downloadVisible,
            openDownload,
            closeDownload,
            downloadColumnsData,
            downloadTableData,
            downloadRowSelection,
            select,
            initValue,
            buList,
            routerJump,
        };
    },
});
